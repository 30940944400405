<template>
  <div>
    <div class="wrap">
      <div class="top-area">
        <div class="title">Redeem</div>
      </div>
    </div>
    <div class="redeem-content-wrap">
      <div class="redeem-image-content-wrap">
        <div class="redeem-wine-image" :style="{backgroundImage:`url(${redeemSendData.image})`}"></div>
        <div class="redeem-wine-image-line"></div>
        <div class="redeem-wine-info">
          <h2>{{ redeemSendData.nft_name }}</h2>
        </div>
      </div>
      <div class="redeem-wine-input-wrap">
        <div class="redeem-input-item-list">

          <!--<div class="redeem-input-info-item">
              <div class="redeem-input-info-title">
                  <span>Redeem 신청 수량</span>
                  <span>소유 수량 <b>3개</b></span>
              </div>
              <div class="redeem-input-info">
                  <input type="text" class="winex-input" placeholder="1">
              </div>
          </div>-->

          <!--                    <div class="redeem-input-info-item">-->
          <!--                        <div class="redeem-input-info-title">-->
          <!--                            <span>국가<span class="required-title">*</span></span>-->
          <!--                        </div>-->
          <!--                        <div class="redeem-input-info">-->
          <!--                            <DefaultDropDown :list-item="countryList" :selectedData.sync="redeemSendData.country" :place-holder="'국가을 선택해주세요'"></DefaultDropDown>-->
          <!--                        </div>-->
          <!--                    </div>-->

          <div class="redeem-input-info-item">
            <div class="redeem-input-info-title">
              <span>Last Name<span class="required-title">*</span></span>
            </div>
            <div class="redeem-input-info">
              <input type="text" class="winex-input" placeholder="성을 입력해 주세요." v-model="redeemSendData.last_name">
            </div>
          </div>

          <div class="redeem-input-info-item">
            <div class="redeem-input-info-title">
              <span>First Name<span class="required-title">*</span></span>
            </div>
            <div class="redeem-input-info">
              <input type="text" class="winex-input" placeholder="이름을 입력해 주세요." v-model="redeemSendData.first_name">
            </div>
          </div>


          <div class="redeem-input-info-item">
            <div class="redeem-input-info-title">
              <span>Phone Num<span class="required-title">*</span></span>
            </div>
            <div class="redeem-input-info">
              <div class="input-phone-number-wrap">
                <input type="text" class="winex-input" placeholder="연락처를 입력해주세요" v-model="redeemSendData.phone_number"
                       @input="phoneHandle">
                <!--                                <span>-</span>
                                                <input type="text" class="winex-input" placeholder="0000" v-model="redeemSendData.phone_number_second">
                                                <span>-</span>
                                                <input type="text" class="winex-input" placeholder="0000" v-model="redeemSendData.phone_number_third">-->
              </div>
            </div>
          </div>

          <div class="redeem-input-info-item">
            <div class="redeem-input-info-title">
              <span>E-mail<span class="required-title">*</span></span>
            </div>
            <div class="redeem-input-info">
              <input type="email" class="winex-input" placeholder="Email을 입력해 주세요." v-model="redeemSendData.email">
            </div>
          </div>

          <div class="redeem-input-info-item">
            <div class="redeem-input-info-title">
              <span>개인통관번호<span class="required-title">*</span></span>
            </div>
            <div class="redeem-input-info">
              <input type="text" class="winex-input" placeholder="개인 통관번호를 입력해 주세요."
                     v-model="redeemSendData.personal_customs_number">
            </div>
          </div>

          <div class="redeem-input-info-item">
            <div class="redeem-input-info-title">
              <span>Address<span class="required-title">*</span></span>
            </div>
            <div class="redeem-input-info">
              <div class="input-address-wrap">
                <div class="input-zip-code-wrap">
                  <!--
                  <input type="text" class="winex-input" placeholder="우편번호를 검색해 주세요." readonly
                         v-model="redeemSendData.postcode">
                  <button class="winex-btn primary-btn" @click="searchZipCode()">검색</button>
                  <button class="winex-btn primary-btn" @click="searchZipCodeEng()">영문</button>
                  -->
                  <input type="text" class="winex-input" placeholder="우편번호를 검색해 주세요." v-model="redeemSendData.postcode" />
                </div>
                <!--
                <input type="text" class="winex-input" readonly placeholder="기본 주소"
                       v-model="redeemSendData.default_address">
                -->
                <input type="text" class="winex-input" placeholder="상세 주소" v-model="redeemSendData.default_address" />
              </div>

            </div>
          </div>

          <div class="redeem-input-info-item">
            <div class="redeem-input-info-title">
              배송방법
            </div>
            <div class="redeem-input-info">
              <div class="input-delivery-type">
                <div class="input-delivery-type-radio-wrap">
                  <label class="winex-radio" @click="openAlertModal('알림','리퍼 컨테이너 이외의 다른 배송방법은 현재 준비중 입니다.')"><input
                      type="radio" name="radio-test" value="1" v-model="shippingMethodNum" disabled><span>익스 프레스</span></label>
                  <label class="winex-radio" @click="openAlertModal('알림','리퍼 컨테이너 이외의 다른 배송방법은 현재 준비중 입니다.')"><input
                      type="radio" name="radio-test" value="2" v-model="shippingMethodNum" disabled><span>스탠다드 에어</span></label>
                  <label class="winex-radio"><input type="radio" name="radio-test" value="3"
                                                    v-model="shippingMethodNum" @click="changeShippingMethod('3')"><span>리퍼 컨테이너</span></label>
                </div>
                <div class="input-delivery-type-text">
                  <h2>병수와 상관 없이 바로 배송을 시작합니다.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 배송 방법 및 결제 배송비는 일단 배제 -->
        <div class="redeem-input-btn-wrap">
          <button class="winex-btn outline-white-lld-btn">Previous</button>
          <button class="winex-btn primary-btn" @click="openInfoCheckModal">Checkout</button>
        </div>
      </div>
    </div>
    <AlertModal
        :title="alertTitle"
        :content="alertContent"
        :pc-width="400"
        :button-class="'primary-btn'"
        :is-open="defaultAlertModalOpen"
        :confirm-click-btn="closeAlertModal"></AlertModal>

    <AlertModal
        :use-custom="true"
        :is-open="redeemInfoCheckModalOpen"
        :pc-width="960"
        class="redeem-info-last-check-wrap-modal">
      <template v-slot:custom>
        <div class="redeem-info-last-check-wrap">
          <div class="title">
            기재하신 내용이 확실한가요?
          </div>
          <div class="wine-info-wrap">
            <div class="wine-image" :style="{backgroundImage:`url(${redeemSendData.image})`}"></div>
            <div class="wine-content-wrap">
              <div class="wine-name-wrap">
                <!--<h4 class="brand">{{redeemSendData.brand}}</h4>-->
                <h2 class="name">{{ redeemSendData.nft_name }}</h2>
              </div>
              <div class="redeem-check-info-table-wrap">
                <table class="redeem-check-info-table">
                  <!--<tr>
                      <th>Country</th>
                      <td>{{redeemSendData.country}}</td>
                  </tr>-->
                  <tr>
                    <th>Last Name</th>
                    <td>{{ redeemSendData.last_name }}</td>
                  </tr>
                  <tr>
                    <th>First Name</th>
                    <td>{{ redeemSendData.first_name }}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{{ redeemSendData.phone_number }}</td>
                  </tr>
                  <tr>
                    <th>Shipping address</th>
                    <td>
                      {{ "(" + redeemSendData.postcode + ")" + redeemSendData.default_address}}
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{{ redeemSendData.email }}</td>
                  </tr>
                  <tr>
                    <th>Personal Customs Number</th>
                    <td>{{ redeemSendData.personal_customs_number }}</td>
                  </tr>
                  <tr>
                    <th>Shipping Method</th>
                    <td>{{ shippingMethodText(Number(redeemSendData.shipping_method)) }}</td>
                  </tr>

                </table>
              </div>

            </div>
          </div>
<!--          <div class="guide-content-wrap">
            <p class="guide-content">
              제출 후에는 내용 수정이 불가하오니, 다시 한번 확인 부탁드립니다.</p>
          </div>-->
          <div class="redeem-info-last-check-btn-wrap">
            <button class="winex-btn outline-white-lld-btn" @click="closeInfoCheckModal">Cancel</button>
            <button class="winex-btn primary-btn" @click="openConfirmModal">Redeem</button>
          </div>
        </div>
      </template>
    </AlertModal>

    <ConfirmModal
        :title="'Redeem을 요청하시겠습니까?'"
        :content="'Redeem 요청시 현재 소유중인 NFT는 거래불가 되며,<br/>복구하실 수 없습니다.'"
        :button-content="'Redeem'"
        :button-class="'primary-btn'"
        :confirm-click-btn="sendRedeem"
        :cancel-button-content="'Cancel'"
        :cancel-click-btn="closeRedeemModal"
        :cancel-button-class="'outline-white-lld-btn'"
        :is-open="redeemModelOpenCheck"
        :is-icon="true"
        :custom-icon="require('@/assets/images/icon/redeem-icon.png')">

    </ConfirmModal>
  </div>
</template>


<script>
// TODO :: 데이터 연동, 소유 수량 이상 신청 수량입력 불가하게 하기, 데이터 입력시 각종 예외 처리
import AlertModal from "../../components/modals/AlertModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import getContractApi from "../../api/contract";

export default {
  name: "redeem.vue",
  components: {ConfirmModal, AlertModal},
  data() {
    return {
      constract: undefined,
      defaultAlertModalOpen: false,
      alertTitle: '알림',
      alertContent: '준비중 입니다.',
      deliveryType: '리퍼 컨테이너',
      countryList: [
        {
          value: '한국',
          text: '한국'
        },
        {
          value: '미국',
          text: '미국'
        },
        {
          value: '프랑스',
          text: '프랑스'
        },
        {
          value: '이탈리아',
          text: '이탈리아'
        }
      ],
      checkOwner: false,
      redeemModelOpenCheck: false,
      redeemInfoCheckModalOpen: false,
      // 가져온 NFT 정보 입니다.

      wineInfo: {
        brand: 'Cote de Nuits',
        name: 'Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019',
        key: 1,
        price: {
          coin: 1270,
          won: 5260000
        },
        description: "<div class=\"sc-1xf18x6-0 haVRLx item--description-text\"><div class=\"sc-edvquz-0 fWQpLM\"><p>Domaine Comte Georges de Vogue Musigny Blanc Grand Cru 2019</p><p><a class=\"sc-1pie21o-0 elyzfO\" href=\"https://luolle.com/product/MusignyBlanc2019\" rel=\"nofollow noopener\" target=\"_blank\">https://luolle.com/product/MusignyBlanc2019</a></p></div></div>",
        image: "https://cdn.imweb.me/thumbnail/20220508/45ceb1e85eda6.png"
      },

      // 실제 서버로 보낼 데이터 입니다.
      redeemSendData: {
        nft_key: '',
        user_key: '',
        nft_address: '',
        nft_id: '',
        image: '',
        nft_name: '[NFT]',
        country: '',
        recipient: '',
        phone_number: '',
        postcode: '',
        default_address: '',
        detail_address: '',
        personal_customs_number: '',
        shipping_method: '',
        email: '',
        first_name: '',
        last_name: ''
      },
      shippingMethod: null,
      shippingMethodNum: null,
      phoneNumber: ''
    }
  },
  created() {
    this.contract = getContractApi();
    // 해당 화면으로 이동할 때 NFT 키값과 NFT NAME값을 넣어주세요.
    // 지갑에 로그인된 유저의 키값을 this.redeemSendData.user_key에 넣어주세요.

    this.redeemSendData.user_key = this.$route.query.user_key;
    this.redeemSendData.address = this.$route.query.address;
    this.redeemSendData.nft_id = this.$route.query.tokenId;
  

    // address 부분이 nft_address 입니다
    // this.redeemSendData.nft_key = `${this.redeemSendData.nft_address}/${this.redeemSendData.nft_id}`;
    // 기존 위에서 수정 하였습니다.
    this.redeemSendData.nft_key = `${this.redeemSendData.nft_id}`;


    this.redeemSendData.nft_name = this.$route.query.name;

    // 해당 키값으로 해당 유저가 보유하고 있는지 판단해 주세요.

    const _this = this;

    // TODO:: 잠시 주석처리 (테스트용)
    // 만약 본인 소유의 NFT가 맞다면
    this.contract.getUserAddress().then(account => {
      this.checkOwner = (account === this.redeemSendData.user_key);
      if (!this.checkOwner) {
        alert('NFT 소유를 확인할 수 없습니다.');
        // this.$router.back();
      } else {
        this.$store.dispatch('userProfile/getUserProfile', {wallet_address: account}).then(res => {

          _this.redeemSendData.image = _this.$route.query.image.toString();

          if(res){
            _this.redeemSendData = {..._this.redeemSendData, ...res}
            // _this.redeemSendData = res
            _this.redeemSendData.personal_customs_number = res.customer_number;
            _this.redeemSendData.postcode = res.zipcode;
            _this.redeemSendData.phone_number = res.phone;
          }
          
        })
      }
    });
  },
  methods: {
    openConfirmModal: function () {
      this.redeemModelOpenCheck = true
    },
    closeRedeemModal: function () {
      this.redeemModelOpenCheck = false
    },
    openInfoCheckModal: function () {
      // Input data 확인 절차..
      let mail_format = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let postcode_format = /^[0-9]{1,10}$/;
      if (!this.redeemSendData.last_name || this.redeemSendData.last_name === '') {
        this.openAlertModal('알림', 'Last Name을 입력해 주세요.')
      } else if (!this.redeemSendData.first_name || this.redeemSendData.first_name === '') {
        this.openAlertModal('알림', 'First Name을 입력해 주세요.')
      } else if (!this.redeemSendData.phone_number || this.redeemSendData.phone_number === '') {
        this.openAlertModal('알림', '연락처를 입력해 주세요.')
      } else if (!this.redeemSendData.email || this.redeemSendData.email === '') {
        this.openAlertModal('알림', '이메일을 입력해 주세요.')
      } else if (!mail_format.test(this.redeemSendData.email)) {
        this.openAlertModal('알림', '정확한 이메일을 입력해 주세요.')
      } else if (!this.redeemSendData.personal_customs_number || this.redeemSendData.personal_customs_number === '') {
        this.openAlertModal('알림', '개인 통관번호를 입력해 주세요.')
      } else if (!this.redeemSendData.postcode || this.redeemSendData.postcode === '') {
        this.openAlertModal('알림', '우편번호를 입력해 주세요.')
      } else if (!postcode_format.test(this.redeemSendData.postcode)) {
        this.openAlertModal('알림', '우편번호를 확인해 주세요.')
      } else if (!this.redeemSendData.default_address || this.redeemSendData.default_address === '') {
        this.openAlertModal('알림', '상세주소를 입력해 주세요.')
      } else if (!this.redeemSendData.shipping_method) {
        this.openAlertModal('알림', '배송 방법을 선택해 주세요.')
      } else {
        this.redeemInfoCheckModalOpen = true
      }

    },
    closeInfoCheckModal: function () {
      this.redeemInfoCheckModalOpen = false
    },
    changeShippingMethod: function (shippingMethodNum) {
      this.redeemSendData.shipping_method = shippingMethodNum
    },
    shippingMethodText(num) {
      switch (num) {
        case 1:
          return "익스 프레스"
        case 2:
          return "스탠다드 에어"
        case 3:
          return "리퍼 컨테이너"
      }
    },
    searchZipCode: function () {
      let self = this
      new window.daum.Postcode({
        oncomplete: function (data) {
          self.redeemSendData.postcode = data.zonecode
          self.redeemSendData.default_address = data.roadAddress
        }
      }).open();
    },
    searchZipCodeEng: function () {
      let self = this
      new window.daum.Postcode({
        oncomplete: function (data) {
          self.redeemSendData.postcode = data.zonecode
          self.redeemSendData.default_address = data.addressEnglish
        }
      }).open();
    },
    openAlertModal(title, content) {
      this.alertTitle = title
      this.alertContent = content
      this.defaultAlertModalOpen = true
    },
    closeAlertModal() {
      this.defaultAlertModalOpen = false
    },
    sendRedeem() {
      let self = this

      // this.redeemSendData.phone_number = this.phoneNumber;

      if (this.redeemSendData.nft_key === '') {
        this.openAlertModal('알림', '오류가 발생하였습니다. 새로고침 후 다시 시도해 주세요.')
      } else if (this.redeemSendData.user_key === '') {
        this.openAlertModal('알림', '오류가 발생하였습니다. 새로고침 후 다시 시도해 주세요.')
      } else if (this.redeemSendData.phone_number === '') {
        this.openAlertModal('알림', '연락처를 입력해 주세요.')
      } else if (this.redeemSendData.personal_customs_number === '') {
        this.openAlertModal('알림', '개인 통관번호를 입력해 주세요.')
      } else if (this.redeemSendData.postcode === '') {
        this.openAlertModal('알림', '우편번호를 검색해 주세요.')
      } else if (this.redeemSendData.default_address === '') {
        this.openAlertModal('알림', '우편번호를 검색해 주세요.')
      } else {
        // this.redeemSendData.phone_number = this.redeemSendData.phone_number_first+'-'+this.redeemSendData.phone_number_second+'-'+this.redeemSendData.phone_number_third
        this.redeemSendData.phone_number = `${this.redeemSendData.phone_number.slice(0,3)}-${this.redeemSendData.phone_number.slice(3,7)}-${this.redeemSendData.phone_number.slice(7)}`
        // console.dir(this.redeemSendData.phone_number)

        self.submitRedeemModal();

      }
    },
    phoneHandle() {
      this.redeemSendData.phone_number = this.redeemSendData.phone_number.replaceAll(/[^0-9]/g, '');

    },
    submitRedeemModal() {
      let self = this
      this.redeemModelOpenCheck = false;
      const titleMsg = "Redeem 요청";
      const tokenId = this.redeemSendData.nft_id;
      
      const market = this.contract.getWineMarket();
    
      this.$store.commit('loading/init', {title: titleMsg, content: '연결 확인 중입니다.(1/2)'})
      self.$store.dispatch('redeem/appendRedeem', this.redeemSendData).then((data) => {
        this.contract.getUserAddress().then(() => {
          return market.requestRedeemNft(tokenId, false, (statusMsg, stVal) => {
            this.$store.commit('loading/init', {title: titleMsg, content: statusMsg})
            console.dir(stVal)
          })
        }).then(() => {
          this.flagModalOnOff = true;
          this.$store.commit('loading/done');
          this.closeAlertModal();
          this.closeInfoCheckModal();
        }).catch(e => {
          console.log(e);
          this.flagModalOnOff = true;
          this.$store.commit('loading/done');
          this.openAlertModal("오류", "Redeem 요청이 실패하였습니다.");
        }).then(() => {
          self.$router.push('redeem/complete/' + data.data.redeem_no)
        });
      }).catch(() => {
        alert('리딤 정보 등록중 오류가 발생하였습니다.')
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/redeem.scss";
</style>
